import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import ProductList from '../../../../components/productList'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginRight:theme.spacing(2),
        marginLeft:theme.spacing(2),
        [theme.breakpoints.down('md')]:{
            marginTop: theme.spacing(2),
        }
    },

}))

const ProductListPageDogsEn = ({ data,location}) => {
    const classes = useStyles()
     /* eslint eqeqeq: 0 */
    const dogWetfood = data.allContentfulProduct.nodes.filter(el => (!el.for_cats & el.type == 'Yaş Mama'))
     /* eslint eqeqeq: 0 */
    const dogDryFood = data.allContentfulProduct.nodes.filter(el => (!el.for_cats & el.type == 'Kuru Mama'))
    return (
        <Layout nodeLocale='en-EU' location={location}>
            <SEO lang='en-EU' title='Irresistably delicious and healthy dog recipes - PROCHOICE' location={location}/>
            <div className={classes.root}>
                <Grid container spacing={4} id='dog-dry'>
                    <ProductList products={dogDryFood} title='Dry Food' nodeLocale='en-EU' />
                </Grid>
               
                <Grid container spacing={4} id='dog-wet'>
                    <ProductList products={dogWetfood} title='Wet Food' nodeLocale='en-EU' />
                </Grid>
            </div>
        </Layout>
    )
}

export const query = graphql`
query ProductListDogQueryEN {
    allContentfulProduct(filter: {brand: {brandName: {eq: "Prochoice"}, node_locale: {eq: "en-EU"}}}) {
      nodes {
        ageGroup
        breeds
        contentful_id
        for_cats
        name
        id
        segment
        slug
        teaser
        type
        labels {
            id
            name
            label
          }
        packagePhoto {
            fluid(maxHeight: 360, quality: 100) {
                ...GatsbyContentfulFluid,
                
            }
        }
      }
    }
}
`
export default ProductListPageDogsEn